<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockURLView from '@shared/components/ContentBlocks/Views/URL.vue';
import APIStore from '@app/services/API/Store';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockURLView },
  static: {
    data: {
      url: '',
      caption: '',
      host: '',
      title: '',
      image: '',
      description: '',
      authorName: '',
      authorUrl: {},
    },
  },
  data: () => ({ isForm: true }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    handle() {
      return APIStore.getUrlInfos(this.authStore.uuid, this.newData.url)
        .then((data) => Object.assign(this.newData, data.data));
    },
    handleError(error) {
      if (error.response.status === 400) {
        this.$buefy.notification.open({ type: 'is-danger', message: 'Cette URL n\'existe pas.' });
      } else {
        this.$errorHandlers.axios(error);
      }
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field label="Lien (https://unsite.fr) *">
        <b-input v-model="newData.url" pattern="^https?:\/\/.+" required />
      </b-field>
      <b-field label="Légende">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockURLView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
