<script>
import { required } from 'vuelidate/lib/validators';
import ContentBlockWithUploadMixin from '@shared/mixins/ContentBlockWithUpload';
import ContentBlockPDFViewerView from '@shared/components/ContentBlocks/Views/PDFViewer.vue';

export default {
  mixins: [ContentBlockWithUploadMixin],
  components: { ContentBlockPDFViewerView },
  static: {
    data: { path: '', caption: '' },
  },
  data: () => ({
    isForm: true,
  }),
  validations: {
    newData: {
      path: { required },
    },
  },
  methods: {
    handleFile() {
      if (this.local.file.size > this.$constants.MAX_PDF_SIZE) {
        this.$buefy.dialog.alert(`
          Le fichier dépasse 200 Mo (trop volumineux). <br>
          N'hésitez pas à découper votre fichier PDF en plusieurs parties.
        `);
        return;
      }

      this.uploadFile(this.viewProps.store, 'pdf');
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <b-field label="Fichier PDF" v-bind="$getErrorProps($v.newData.path, ['required'])">
            <b-upload accept="application/pdf" v-model="local.file" expanded @input="handleFile">
              <b-button tag="a" class="button" icon-left="file-pdf" outlined>
                Sélectionnez un fichier PDF
              </b-button>
            </b-upload>
          </b-field>
          <p class="help is-success">
            Format .pdf, max 200 Mo
          </p>
        </div>
        <div class="column">
          <b-field label="Légende">
            <b-input v-model="newData.caption" />
          </b-field>
        </div>
        <div class="column is-12 has-text-centered">
          <template v-if="local.file">
            <b-progress
              v-if="!local.progressValue || local.progressValue < local.progressTotal"
              class="mb-2"
              type="is-success"
              :value="local.progressValue"
              :max="local.progressTotal"
              format="percent"
              show-value
            />
            <p v-else-if="saveIsDisabled">
              Traitement du média...
            </p>
            <p class="is-size-8 mb-5">
              {{ local.file.name }} - {{ localFileSize }} Mo
            </p>
          </template>
          <figure class="p-3 has-background-grey bradius-8">
            <div v-if="newData.path">
              <ContentBlockPDFViewerView :data="newData" />
            </div>
            <p v-else style="line-height:140px">
              Pas de PDF
            </p>
          </figure>
          <p v-show="newData.path">
            <b-button type="is-text has-text-danger" @click="newData.path = ''">
              Supprimer
            </b-button>
          </p>
        </div>
      </div>
    </template>
    <template #view>
      <ContentBlockPDFViewerView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
