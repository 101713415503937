<script>
import 'highlight.js/styles/monokai.css';
import hljs from 'highlight.js';
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockMarkdown from '@shared/components/ContentBlocks/Views/Markdown.vue';
import MarkdownIt from 'markdown-it';
import MarkdownItAbbr from 'markdown-it-abbr';
import MarkdownItSub from 'markdown-it-sub';
import MarkdownItSup from 'markdown-it-sup';
import MarkdownItDeflist from 'markdown-it-deflist';

const md = MarkdownIt({
  linkify: true,
  typographer: true,
  langPrefix: 'hljs language-',
  highlight(str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch (__) { return ''; }
    }

    return '';
  },
});

md.use(MarkdownItAbbr)
  .use(MarkdownItSub)
  .use(MarkdownItSup)
  .use(MarkdownItDeflist);

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockMarkdown },
  static: {
    data: { content: '', html: '' },
  },
  data: () => ({ isForm: true }),
  methods: {
    handle() {
      this.newData.html = md.render(this.newData.content);
      return Promise.resolve();
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field label="Contenu Markdown (MD)">
        <div>
          <div
            v-if="notification"
            class="notification"
            :class="notificationClass"
            v-html="notification"
          />
          <b-input v-model="newData.content" type="textarea" rows="15" required />
        </div>
      </b-field>
    </template>
    <template #view>
      <ContentBlockMarkdown v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
