<script>
import ContentBlockWithUploadMixin from '@shared/mixins/ContentBlockWithUpload';
import ContentBlockResourceView from '@shared/components/ContentBlocks/Views/Resource.vue';
import APITrainings from '@app/services/API/Trainings';

export default {
  mixins: [ContentBlockWithUploadMixin],
  components: { ContentBlockResourceView },
  static: {
    data: {
      id: null,
      name: '',
      path: '',
    },
    messages: {
      NO_TRAINING_ITEM: `
        Veuillez sauvegarder votre leçon avant de pouvoir ajouter un fichier à télécharger.
      `,
    },
  },
  data: () => ({
    isForm: true,
    isEditable: false,
  }),
  methods: {
    handle() {
      if (!this.trainingItem || !this.trainingItem.id) {
        return Promise.reject(new Error('NO_TRAINING_ITEM'));
      }

      if (this.local.file.size > this.$constants.MAX_RESOURCE_SIZE) {
        return Promise.reject(new Error('MAX_RESOURCE_SIZE'));
      }

      const { uuid } = this.training;
      return this.uploadFile(this.viewProps.store, 'resource', (url) => {
        const data = {
          name: this.newData.name,
          file: url,
          target: 'CUSTOMER',
        };

        return APITrainings.addTrainingResource(uuid, this.trainingItem.id, data)
          .then((data) => Object.assign(this.newData, data.data));
      });
    },
    handleError(error) {
      if (error.message === 'NO_TRAINING_ITEM') {
        this.$buefy.notification.open({
          type: 'is-danger',
          message: this.$options.static.messages.NO_TRAINING_ITEM,
        });
      } else if (error.message === 'MAX_RESOURCE_SIZE') {
        this.$buefy.dialog.alert(`
          Le fichier dépasse 500 Mo (trop volumineux). <br>
          N'hésitez pas à le compresser, ou à découper votre fichier en plusieurs parties.
        `);
      } else {
        this.$errorHandlers.axios(error);
      }
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div v-if="!trainingItem || !trainingItem.id" class="notification is-warning">
        {{ $options.static.messages.NO_TRAINING_ITEM }}
      </div>
      <template v-else>
        <p class="notification">
          Ce fichier sera aussi disponible dans vos annexes.
        </p>
        <b-field label="Nom du fichier">
          <b-input v-model="newData.name" required />
        </b-field>
        <b-field label="Fichier">
          <b-upload v-model="local.file" expanded required native>
            <b-button tag="a" class="button" icon-left="file" outlined>
              Sélectionnez un fichier
            </b-button>
          </b-upload>
        </b-field>
        <p v-if="local.file" class="is-size-8 mb-5">
          {{ local.file.name }} - {{ localFileSize }} Mo
        </p>
      </template>
    </template>
    <template #view>
      <ContentBlockResourceView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
