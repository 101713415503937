<script>
import { required } from 'vuelidate/lib/validators';
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockRichTextView from '@shared/components/ContentBlocks/Views/RichText.vue';
import TiptapEditor from '@shared/components/TiptapEditor.vue';

export default {
  components: {
    TiptapEditor,
    ContentBlockRichTextView,
  },
  mixins: [ContentBlockMixin],
  static: {
    data: { text: '' },
  },
  validations: {
    newData: {
      text: { required },
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field class="field" v-bind="$getErrorProps($v.newData.text, ['required'])">
        <TiptapEditor :autoFocus="true" :value="data.text" @input="newData.text = $event" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockRichTextView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
