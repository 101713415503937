<script>
export default {
  props: {
    trainingItem: {
      type: Object,
      default: null,
    },
  },
  head: {
    htmlAttrs: {
      class: 'is-clipped',
    },
  },
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
    authUser() {
      return this.$store.getters['auth/user'];
    },
    closeRoute() {
      const trainingItemId = this.trainingItem && this.trainingItem.id;
      const route = {
        name: 'training',
        params: { uuid: this.currentTraining.uuid },
      };

      if (trainingItemId) {
        route.hash = `#training-item-${trainingItemId}`;
      }

      return route;
    },
  },
  methods: {
    scrollInContainer(element, options = {}) {
      options = { ...options, container: this.$refs.body };
      return this.$scrollTo(element, options);
    },
  },
};
</script>

<template>
  <section v-if="authUser" class="elayout has-background-white">
    <header class="elayout_header level is-mobile pl-4 has-background-white">
      <div class="level-left w-50p h-full">
        <p class="w-full has-text-clipped is-size-8">
          {{ currentTraining.name }}
        </p>
      </div>
      <div class="level-right h-full">
        <div class="level-item">
          <slot name="header_right" />
        </div>
        <div class="level-item elayout_close">
          <b-button
            class="is-size-5"
            type="is-text"
            icon-left="times"
            aria-label="Fermer"
            title="Fermer"
            tag="router-link"
            :to="closeRoute"
          />
        </div>
      </div>
    </header>
    <div class="columns is-gapless h-full">
      <div class="elayout_sidenav column has-background-light">
        <div class="elayout_sidenav_main p-3">
          <slot name="sidenav" />
        </div>
      </div>
      <div ref="body" class="elayout_main column pb-20">
        <main class="px-5 pb-5 pt-8">
          <slot name="main" />
        </main>
        <footer class="pb-20 has-text-centered">
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.elayout {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: $navbar-height;

  &_header {
    position: fixed;
    z-index: 40;
    top: 0;
    left: 0;
    right: 0;
    height: $navbar-height;
    box-shadow: $box-shadow;
  }

  &_close {
    height: $navbar-height;
    width: $navbar-height;
    border-left: 1px solid $theme_color_grey_light;
  }

  &_sidenav {
    display: flex;
    flex-direction: column;
    width: 420px;
    flex: 0 0 420px;

    &_header {
      flex: none;
      height: 40px;
      line-height: 40px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    &_main {
      overflow-y: scroll;
      height: 100%;
    }
  }

  &_main {
    overflow: auto;
  }
}
</style>
