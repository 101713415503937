<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockCodeBlockView from '@shared/components/ContentBlocks/Views/CodeBlock.vue';

const langs = {
  auto: 'Détection automatique (peu précis)',
  c: 'C',
  cpp: 'C++',
  csharp: 'C#',
  html: 'HTML',
  xml: 'XML',
  bash: 'Bash',
  coffeescript: 'CoffeeScript',
  css: 'CSS',
  markdown: 'Markdown',
  diff: 'Diff',
  ruby: 'Ruby',
  go: 'GO',
  http: 'HTTP',
  toml: 'TOML',
  java: 'Java',
  javascript: 'JavaScript',
  json: 'JSON',
  kotlin: 'Kotlin',
  less: 'Less',
  lua: 'Lua',
  makefile: 'Makefile',
  perl: 'Perl',
  ngnix: 'Nginx config',
  objectivec: 'Objective-C',
  php: 'PHP',
  plaintext: 'Plain text (.txt)',
  properties: '.properties',
  python: 'Python',
  pycon: 'Python REPL',
  rust: 'Rust',
  scss: 'SCSS',
  shell: 'Shell',
  sql: 'SQL',
  swift: 'Swift',
  yaml: 'YAML',
  typescript: 'TypeScript',
  pgsql: 'PostgreSQL & PL/pgSQL',
  apache: 'Apache',
  clojure: 'Clojure',
  dart: 'Dart',
  django: 'Django',
  elixir: 'Elixir',
  gradle: 'Gradle',
  haml: 'Haml',
  handlebars: 'Handlebars',
  haskell: 'Haskell',
  haxe: 'Haxe',
  tex: 'LaTeX',
  matlab: 'Matlab',
  r: 'R',
  scala: 'Scala',
  svelte: 'Svelte',
  twig: 'Twig',
  vbnet: 'VB.Net',
};

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockCodeBlockView },
  static: {
    langs: Object.keys(langs).sort().reduce((acc, curr) => {
      acc[curr] = langs[curr];
      return acc;
    }, {}),
    data: { text: '', lang: 'auto', caption: '' },
  },
  data: () => ({ isForm: true }),
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field label="Langage">
        <b-select v-model="newData.lang" expanded required>
          <option
            v-for="(lang, k) in $options.static.langs"
            :key="k"
            :value="k"
          >
            {{ lang }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Code">
        <b-input v-model="newData.text" type="textarea" required />
      </b-field>
      <b-field label="Légende">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockCodeBlockView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
