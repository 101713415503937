import ContentBlockMixin from '@shared/mixins/ContentBlock';
import APIUpload from '@shared/services/API/Upload';

export default {
  mixins: [ContentBlockMixin],
  data: () => ({
    local: {
      file: null,
      progressValue: null,
      progressTotal: null,
    },
  }),
  computed: {
    localFileSize() {
      if (this.local.file) {
        return Math.round((this.local.file.size / (1024 * 1024)) * 100) / 100;
      }

      return null;
    },
    localPath() {
      const path = this.newData.path.startsWith('http')
        ? this.newData.path
        : `${this.$env.apiResourcesURL}/${this.data.path}`;

      return path;
    },
  },
  methods: {
    onCancel() {
      if (this.uploadPromise) {
        this.uploadPromise.cancel();
        this.uploadPromise = null;
      }
    },
    uploadFile(store, type, onDone) {
      const params = {
        type,
        file: this.local.file,
        fileableId: this.trainingItem.id,
        fileableType: 'TRAINING_ITEM',
      };

      const onUploadProgress = (event) => {
        this.local.progressValue = event.loaded;
        this.local.progressTotal = event.total;
      };

      this.saveIsDisabled = true;
      this.uploadPromise = APIUpload.upload(params, onUploadProgress)
        .then((file) => {
          const { url } = file;

          if (onDone) {
            return onDone(url);
          }

          this.newData.path = url;
          return url;
        })
        .finally(() => {
          this.saveIsDisabled = false;
          this.local.file = null;
          this.local.progressValue = null;
          this.local.progressTotal = null;
        });

      return this.uploadPromise;
    },
  },
};
