<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockTrainingItemURLView from '@shared/components/ContentBlocks/Views/TrainingItemURL.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockTrainingItemURLView },
  static: {
    data: {
      id: '',
      trainingUuid: '',
      trainingSlug: '',
      title: '',
    },
  },
  data: () => ({ isForm: true }),
  created() {
    this.newData.trainingUuid = this.training.uuid;
    this.newData.trainingSlug = this.training.slug;
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div class="field">
        <h3 class="title is-5">
          Créez un lien vers une leçon
        </h3>
      </div>
      <b-field>
        <b-select v-model="newData.id" required expanded>
          <option value="">
            Sélectionner une leçon
          </option>
          <template v-for="item in viewProps.trainingItems">
            <optgroup v-if="item.children" :key="`section${item.id}`" :label="item.name">
              <option v-for="child in item.children" :key="child.id" :value="child.id">
                {{ child.name }}
              </option>
              <option v-if="item.children.length === 0" class="has-background-light" disabled>
                Pas encore de contenu dans ce chapitre
              </option>
            </optgroup>
            <option v-else :key="`item${item.id}`" :value="item.id">
              {{ item.name }}
            </option>
          </template>
        </b-select>
      </b-field>
    </template>
    <template #view>
      <ContentBlockTrainingItemURLView class="pevents-none" v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
