<script>
import { required } from 'vuelidate/lib/validators';
import ContentBlockWithUploadMixin from '@shared/mixins/ContentBlockWithUpload';
import ContentBlockImageView from '@shared/components/ContentBlocks/Views/Image.vue';

export default {
  mixins: [ContentBlockWithUploadMixin],
  components: { ContentBlockImageView },
  static: {
    data: { path: '', caption: '', to_upload: false },
  },
  data: () => ({ isForm: true }),
  validations: {
    newData: {
      path: { required },
    },
  },
  methods: {
    handleFile(file) {
      if (file.size > this.$constants.MAX_IMAGE_SIZE) {
        this.$buefy.dialog.alert(this.$t('messages.file_larger_than', {
          size: '5 Mo',
        }));
        return;
      }

      const loader = this.$buefy.loading.open();
      this.uploadFile(this.viewProps.store, 'image')
        .finally(() => loader.close());
    },
  },
  computed: {
    newDataPath() {
      if (!this.newData.to_upload) {
        if (this.newData.path.startsWith('http')) return this.newData.path;

        return `${this.$env.apiResourcesURL}/${this.newData.path}`;
      }

      return this.newData.path;
    },
    isBase64() {
      return this.data.path.indexOf('data:image') > -1;
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <b-field label="Image" v-bind="$getErrorProps($v.newData.path, ['required'])">
            <b-upload expanded v-model="local.file" @input="handleFile">
              <b-button tag="a" class="button" icon-left="file-image" outlined>
                Sélectionnez une image
              </b-button>
            </b-upload>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Légende">
            <b-input v-model="newData.caption" />
          </b-field>
        </div>
        <div class="column is-12 has-text-centered">
          <figure class="p-1 has-background-grey bradius-8">
            <img
              v-if="newData.path"
              class="is-block mx-auto bradius-8"
              :src="newDataPath"
            >
            <p v-else style="line-height:140px">
              Pas d'image
            </p>
          </figure>
          <p v-show="newData.path">
            <b-button type="is-text has-text-danger" @click="newData.path = ''">
              Supprimer
            </b-button>
          </p>
        </div>
      </div>
    </template>
    <template #view>
      <ContentBlockImageView v-bind="viewProps" :data="data" :isBase64="isBase64" />
    </template>
  </ContentBlockBase>
</template>
