import cloneDeep from 'lodash/cloneDeep';
import ContentBlockBase from '@shared/components/ContentBlocks/ContentBlockBase.vue';

export default {
  components: {
    ContentBlockBase,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    viewProps: {
      type: Object,
      required: true,
    },
    notification: {
      type: String,
      default: null,
    },
    notificationClass: {
      type: String,
      default: null,
    },
    meta: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      default: () => ({ data: {} }),
    },
    training: {
      type: Object,
      required: true,
    },
    trainingItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    const isNew = !!this.contentBlock.isNew;
    const staticData = {
      ...this.$options.static.data,
      __isWide: false,
    };

    return {
      isNew,
      isForm: false,
      isEditing: isNew,
      isLoading: false,
      isEditable: true,
      saveIsDisabled: false,
      newData: cloneDeep(isNew ? staticData : { ...staticData, ...this.contentBlock.data }),
    };
  },
  computed: {
    data() {
      return this.contentBlock.data;
    },
  },
  methods: {
    handle() {
      return Promise.resolve();
    },
    handleError(error) {
      this.$errorHandlers.axios(error);
    },
    edit() {
      if (!this.isEditable) {
        this.$buefy.dialog.alert(`
          Vous ne pouvez pas modifier ce bloc. <br>
          Supprimez-le et ajoutez un nouveau bloc si besoin.
        `);
        return;
      }

      this.isEditing = true;
      this.onEdit && this.onEdit();
      this.$emit('edit');
    },
    cancel() {
      const isNew = !!this.contentBlock.isNew;
      const staticData = this.$options.static.data;
      const newData = cloneDeep(isNew ? staticData : { ...staticData, ...this.contentBlock.data });

      this.isEditing = false;
      this.onCancel && this.onCancel();
      this.newData = newData;
      this.$emit('cancel');
    },
    moveUp() {
      this.$emit('moveup');
    },
    moveDown() {
      this.$emit('movedown');
    },
    remove() {
      this.$buefy.dialog.confirm({
        message: 'Attention ! <br> Cette action est définitive.',
        type: 'is-danger',
        focusOn: 'cancel',
        onConfirm: () => {
          this.isEditing = false;
          this.$emit('remove');
        },
      });
    },
    save() {
      this.isLoading = true;
      this.handle()
        .then(() => {
          this.isEditing = false;
          this.$emit('save', cloneDeep(this.newData));
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    addAbove() {
      this.$emit('add-above');
    },
  },
};
