<script>
import axios from 'axios';
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import TiktokOEmbed from './Views/TiktokOEmbed.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { TiktokOEmbed },
  static: {
    data: {},
  },
  mounted() {
    this.loadEmbed();
  },
  data: () => ({
    loaded: false,
    isForm: true,
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    html() {
      return this.data.html;
    },
    type() {
      return this.contentBlock.type;
    },
  },
  methods: {
    async loadEmbed() {
      if (!this.newData.url) return;

      const data = await axios.$get(`https://www.tiktok.com/oembed?url=${this.newData.url}`);

      Object.assign(this.newData, data);
    },
    handle() {
      return this.loadEmbed();
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field label="Lien TikTok *">
        <b-input v-model="newData.url" required />
      </b-field>
      <b-field label="Légende">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>

    <template #view>
      <TiktokOEmbed v-bind="viewProps" :data="newData" />
    </template>
  </ContentBlockBase>
</template>
