<script>
import Draggable from 'vuedraggable';
import APITrainings from '@app/services/API/Trainings';
import { genericForms } from '@shared/mixins/forms';
import { mapGetters } from 'vuex';
import APIUpload from '@shared/services/API/Upload';

const forms = {
  name: {
    label: 'Nom de l\'annexe',
    type: 'text',
    column: 'is-12 pb-0',
    inputAttrs: {
      hasCounter: false,
      maxlength: 256,
      required: true,
    },
  },
  link: {
    label: 'Lien (http:// ou https://)',
    type: 'text',
    column: 'is-12 pb-0',
    inputAttrs: {
      pattern: '^https?:\\/\\/.+',
      required: true,
    },
  },
  file: {
    label: 'Fichier',
    type: 'upload',
    column: 'is-12 pb-0',
    isImg: false,
    inputAttrs: {
      required: true,
    },
  },
};

export default {
  mixins: [genericForms],
  components: { Draggable },
  props: {
    training: {
      type: Object,
      required: true,
    },
    trainingItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      resource: { name: '', link: '', file: '' },
      resources: this.trainingItem.resources || [],
      chosenType: 'LINK',
    };
  },
  watch: {
    'trainingItem.resources': function() {
      this.resources = this.trainingItem.resources || [];
    },
    chosenType() {
      this.resource.link = '';
      this.resource.file = '';
    },
  },
  computed: {
    ...mapGetters({ store: 'auth/store' }),

    formFields() {
      if (this.chosenType === 'LINK') {
        return { name: forms.name, link: forms.link };
      }

      return { name: forms.name, file: forms.file };
    },
    dynamicResources() {
      return this.resources.map((resource) => {
        let href;

        if (resource.type === 'LINK' || resource.path.startsWith('http')) {
          href = resource.path;
        } else {
          href = `${this.$env.apiResourcesURL}/${resource.path}`;
        }

        resource.href = href;
        return resource;
      });
    },
  },
  methods: {
    reorder() {
      const { uuid } = this.training;
      const { id } = this.trainingItem;

      const resources = this.resources.map((resource, k) => {
        resource.order = k + 1;
        return resource;
      });

      this.reorderPromise = this.reorderPromise || Promise.resolve();
      this.reorderPromise
        .then(() => APITrainings.reorderTrainingResource(uuid, id, resources))
        .then(() => this.$showMessage.success());
    },
    deleteResource(resource) {
      const loader = this.$buefy.loading.open({ container: this.$el });
      APITrainings.deleteTrainingResource(
        this.training.uuid,
        this.trainingItem.id,
        resource.id,
      )
        .then(() => {
          this.resources = this.resources.filter((v) => v.id !== resource.id);
          this.$showMessage.success();
          this.$emit('delete', resource);
        })
        .finally(() => loader.close());
    },
    action() {
      let promise;
      const resource = { name: this.resource.name, target: 'CUSTOMER' };
      const { uuid } = this.training;
      const link = this.resource.link.trim();

      if (link) {
        resource.link = link;
        promise = APITrainings.addTrainingResource(uuid, this.trainingItem.id, resource);
      } else if (this.resource.file) {
        const params = {
          type: 'resource',
          file: this.resource.file,
          fileableId: this.trainingItem.id,
          fileableType: 'TRAINING_ITEM',
        };

        promise = APIUpload.upload(params).then((file) => {
          resource.file = file.url;
          return APITrainings.addTrainingResource(
            uuid, this.trainingItem.id, resource,
          );
        });
      }

      return promise.then((data) => {
        this.resources.push(data.data);
        this.resource = { name: '', link: '', file: '' };
        this.$emit('change', [...this.resources]);
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="content has-text-centered">
      <p>
        Voici l'endroit idéal pour ajouter des ressources complémentaires.
      </p>
    </div>
    <div class="content has-text-centered is-small">
      <p>
        Ajoutez un <strong>lien</strong> ou un <strong>fichier</strong>.
      </p>
    </div>

    <draggable
      v-if="resources.length"
      v-model="resources"
      tag="ul"
      handle=".handle"
      @change="reorder"
    >
      <li
        v-for="resource in dynamicResources"
        :key="resource.id"
        class="level px-2 mb-2 border-dashed bradius-4 is-unselectable"
      >
        <div class="level-left">
          <b-icon class="handle cursor-grab" icon="grip-lines" />
          <a
            class="is-block p-2 has-text-clipped w-200 has-text-left color-inherit"
            :href="resource.href"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ resource.name }}
          </a>
        </div>
        <div class="level-right">
          <b-button
            type="is-text has-text-danger"
            icon-left="times-circle"
            @click="deleteResource(resource)"
          />
        </div>
      </li>
    </draggable>

    <form
      v-if="trainingItem.id"
      class="columns is-multiline"
      @submit.prevent="dataIsValid(isLoading) && handle()">
      <div class="column is-12 pb-0">
        <b-field label="Type de ressource">
          <b-select v-model="chosenType" expanded>
            <option value="LINK">Lien</option>
            <option value="FILE">Fichier</option>
          </b-select>
        </b-field>
      </div>
      <div v-for="(field, k) in formFields" :key="k" class="column" :class="field.column">
        <BaseField v-model="resource[k]" :field="field" />
      </div>
      <div class="column is-12">
        <b-button type="is-primary" native-type="submit" :loading="isLoading" expanded>
          Ajouter
        </b-button>
      </div>
    </form>
    <div v-else class="notification is-warning is-size-8 -mx-5">
      Pensez à sauvegarder avant d'ajouter des annexes...
    </div>
  </div>
</template>
