<script>
import { required } from 'vuelidate/lib/validators';
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockToggleView from '@shared/components/ContentBlocks/Views/Toggle.vue';
import TiptapEditor from '@shared/components/TiptapEditor.vue';

export default {
  components: {
    TiptapEditor,
    ContentBlockToggleView,
  },
  mixins: [ContentBlockMixin],
  static: {
    data: { title: '', text: '' },
  },
  validations: {
    newData: {
      title: { required },
      text: { required },
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field
        label="Titre"
        v-bind="$getErrorProps($v.newData.title, ['required'])"
      >
        <b-input v-model="newData.title" />
      </b-field>
      <b-field
        label="Texte à déplier"
        v-bind="$getErrorProps($v.newData.text, ['required'])"
      >
        <TiptapEditor
          :autoFocus="true"
          :value="data.text"
          @input="newData.text = $event"
        />
      </b-field>
    </template>
    <template #view>
      <ContentBlockToggleView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
