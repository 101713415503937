<script>
import ContentBlockEmbedSimpleView from '@shared/components/ContentBlocks/Views/EmbedSimple.vue';
import ContentBlockMixin from '@shared/mixins/ContentBlock';

export default {
  components: { ContentBlockEmbedSimpleView },
  mixins: [ContentBlockMixin],
  static: {
    data: {
      url: '',
      caption: '',
    },
  },
  data: () => ({ isForm: true }),
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field :label="`Lien ${label} *`">
        <b-input v-model="newData.url" required />
      </b-field>
      <div class="field">
        <b-switch v-model="newData.__isWide">
          Pleine largeur
        </b-switch>
      </div>
      <b-field label="Légende">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockEmbedSimpleView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
