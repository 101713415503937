<script>
import deburr from 'lodash/deburr';
import pickBy from 'lodash/pickBy';
import blockTypes from '@shared/assets/data/training_item_content_block_types';

export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  static: {
    blockTypes,
  },
  data() {
    return { query: '' };
  },
  computed: {
    blockTypes() {
      return pickBy(
        this.$options.static.blockTypes,
        (block) => deburr(block.text.toLowerCase())
          .includes(deburr(this.query.trim().toLowerCase())),
      );
    },
    blockTypesCount() {
      return Object.keys(this.blockTypes).length;
    },
  },
  watch: {
    autofocus(val) {
      val && this.$nextTick(() => this.$refs.bi.$refs.input.focus());
    },
  },
  methods: {
    onSearch(event) {
      this.query = event;
    },
  },
};
</script>

<template>
  <div class="blocks">
    <form class="mb-3" @submit.prevent>
      <b-input
        ref="bi"
        type="search"
        placeholder="Chercher un contenu"
        :value="query"
        @input="onSearch"
      />
    </form>
    <div v-if="blockTypesCount" class="columns is-multiline is-mobile is-gapless">
      <div
        v-for="(block, type) in blockTypes"
        :key="type"
        class="column p-3"
        :class="block.size || 'is-6'">
        <div class="p-1">
          <button
            class="blocks_btn bradius-8 cursor-pointer"
            type="button"
            @click="$emit('add', type)"
          >
            <b-icon :pack="block.pack" :icon="block.icon" size="is-large" />
            <br>
            <span class="is-size-7">
              {{ block.text }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <p v-else class="box">
      Aucun contenu ne correspond à la recherhce <strong>{{ query }}</strong>.
    </p>
  </div>
</template>

<style lang="scss" scoped>
.blocks {
  &_btn {
    display: block;
    height: 100px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: $white;

    &:hover {
      color: $white;
      background: $primary;
    }
  }
}
</style>
