<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockHTMLView from '@shared/components/ContentBlocks/Views/HTML.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockHTMLView },
  static: {
    data: { html: '', caption: '' },
  },
  data: () => ({ isForm: true }),
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field label="Contenu HTML (code)">
        <div>
          <div
            v-if="notification"
            class="notification"
            :class="notificationClass"
            v-html="notification"
          />
          <b-input v-model="newData.html" type="textarea" required />
        </div>
      </b-field>
      <b-field label="Légende">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockHTMLView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
