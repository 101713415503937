<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockSeparatorView from '@shared/components/ContentBlocks/Views/Separator.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockSeparatorView },
  static: {
    data: {
      marginY: 24,
    },
  },
  data: () => ({ isForm: true }),
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field label="Marges (en pixel)">
        <b-input
          v-model.number="newData.marginY"
          type="number"
          min="20"
          required
        />
      </b-field>
    </template>
    <template #view>
      <ContentBlockSeparatorView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
