<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <b-dropdown
      :value="value"
      expanded
      @input="$emit('input', $event)"
    >
      <template #trigger>
        <b-button
          class="flex-between-center"
          icon-right="chevron-down"
          expanded
        >
          <b-icon
            class="mr-1 is-size-9"
            icon="circle"
            :type="value ? 'is-warning' : 'is-success'"
          />
          <slot v-if="value" name="draft" />
          <slot v-else name="published" />
        </b-button>
      </template>

      <b-dropdown-item :value="true">
        <b-icon
          class="mr-1 is-size-9"
          icon="circle"
          type="is-warning"
        />

        <slot name="draft" />
      </b-dropdown-item>
      <b-dropdown-item :value="false">
        <b-icon
          class="mr-1 is-size-9"
          icon="circle"
          type="is-success"
        />

        <slot name="published" />
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
