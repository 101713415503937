<script>
// import DOMPurify from 'dompurify';
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import APIStore from '@app/services/API/Store';
import OEmbed from './Views/OEmbed.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { OEmbed },
  static: {
    data: {
      url: '',
      caption: '',
      html: '',
    },
  },
  data: () => ({ isForm: true }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    html() {
      return this.data.html;
    },
  },
  methods: {
    handle() {
      return APIStore.getOEmbed(this.authStore.uuid, this.newData.url)
        .then((data) => Object.assign(this.newData, data.data));
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field label="Lien (https://unsite.fr) *">
        <b-input v-model="newData.url" pattern="^https?:\/\/.+" required />
      </b-field>
      <b-field label="Légende">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <OEmbed v-bind="viewProps" :data="newData" />
    </template>
  </ContentBlockBase>
</template>
