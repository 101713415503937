import ContentBlockHTML from '@shared/components/ContentBlocks/ContentBlockHTML.vue';
import ContentBlockMarkdown from '@shared/components/ContentBlocks/ContentBlockMarkdown.vue';
import ContentBlockRichText from '@shared/components/ContentBlocks/ContentBlockRichText.vue';
import ContentBlockToggle from '@shared/components/ContentBlocks/ContentBlockToggle.vue';
import ContentBlockSeparator from '@shared/components/ContentBlocks/ContentBlockSeparator.vue';
import ContentBlockResource from '@shared/components/ContentBlocks/ContentBlockResource.vue';
import ContentBlockURL from '@shared/components/ContentBlocks/ContentBlockURL.vue';
import ContentBlockTrainingItemURL from '@shared/components/ContentBlocks/ContentBlockTrainingItemURL.vue';
import ContentBlockOEmbed from '@shared/components/ContentBlocks/ContentBlockOEmbed.vue';
import ContentBlockTiktokOEmbed from '@shared/components/ContentBlocks/ContentBlockTiktokOEmbed.vue';
import ContentBlockImage from '@shared/components/ContentBlocks/ContentBlockImage.vue';
import ContentBlockVideo from '@shared/components/ContentBlocks/ContentBlockVideo.vue';
import ContentBlockAudio from '@shared/components/ContentBlocks/ContentBlockAudio.vue';
import ContentBlockPDFViewer from '@shared/components/ContentBlocks/ContentBlockPDFViewer.vue';
import ContentBlockEmbed from '@shared/components/ContentBlocks/ContentBlockEmbed.vue';
import ContentBlockSimpleEmbed from '@shared/components/ContentBlocks/ContentBlockEmbedSimple.vue';
// import ContentBlockEmbedo from '@shared/components/ContentBlocks/ContentBlockEmbedo.vue';
import ContentBlockHeading from '@shared/components/ContentBlocks/ContentBlockHeading.vue';
import ContentBlockCodeBlock from '@shared/components/ContentBlocks/ContentBlockCodeBlock.vue';
import ContentBlockNotification from '@shared/components/ContentBlocks/ContentBlockNotification.vue';

export default {
  video: {
    icon: 'file-video',
    pack: 'fas',
    text: 'Vidéo',
    component: ContentBlockVideo,
  },
  audio: {
    icon: 'file-audio',
    pack: 'fas',
    text: 'Audio',
    component: ContentBlockAudio,
  },
  richtext: {
    icon: 'paragraph',
    pack: 'fas',
    text: 'Texte',
    component: ContentBlockRichText,
  },
  separator: {
    icon: 'minus',
    pack: 'fas',
    text: 'Séparateur',
    component: ContentBlockSeparator,
  },
  image: {
    icon: 'file-image',
    pack: 'fas',
    text: 'Image',
    component: ContentBlockImage,
  },
  heading: {
    icon: 'heading',
    pack: 'fas',
    text: 'Titre',
    component: ContentBlockHeading,
  },
  notification: {
    icon: 'info-circle',
    pack: 'fas',
    text: 'Bloc d\'information',
    component: ContentBlockNotification,
  },
  markdown: {
    icon: 'markdown',
    pack: 'fab',
    text: 'Contenu Markdown',
    notification: `
      Vous pouvez insérer ici du contenu Markdown avec la syntaxe de base.
      Le HTML n'est pas autorisé à l'intérieur du contenu Markdown.
    `,
    notificationClass: 'is-info',
    component: ContentBlockMarkdown,
  },
  resource: {
    size: 'is-12',
    icon: 'file',
    pack: 'fas',
    text: 'Fichier à télécharger (PDF, ...)',
    component: ContentBlockResource,
  },
  pdf_viewer: {
    icon: 'file-pdf',
    pack: 'fas',
    text: 'Visionneuse PDF',
    component: ContentBlockPDFViewer,
  },
  url: {
    icon: 'link',
    pack: 'fas',
    text: 'Lien',
    component: ContentBlockURL,
  },
  training_item_url: {
    icon: 'external-link-square-alt',
    pack: 'fas',
    text: 'Lien vers une leçon',
    component: ContentBlockTrainingItemURL,
  },
  giphy: {
    icon: 'images',
    pack: 'fas',
    text: 'Gif (Giphy)',
    component: ContentBlockEmbed,
  },
  calendly: {
    icon: 'calendar-plus',
    pack: 'fas',
    text: 'Calendly',
    component: ContentBlockSimpleEmbed,
  },
  typeform: {
    icon: 'poll-h',
    pack: 'fas',
    text: 'Typeform',
    component: ContentBlockSimpleEmbed,
  },
  youtube: {
    icon: 'youtube',
    pack: 'fab',
    text: 'Vidéo YouTube',
    component: ContentBlockEmbed,
  },
  youtube_live: {
    icon: 'video',
    pack: 'fas',
    text: 'Vidéo Live (YouTube)',
    component: ContentBlockEmbed,
  },
  vimeo: {
    icon: 'vimeo',
    pack: 'fab',
    text: 'Vidéo Vimeo',
    component: ContentBlockEmbed,
  },
  dailymotion: {
    icon: 'dailymotion',
    pack: 'fab',
    text: 'Vidéo Dailymotion',
    component: ContentBlockEmbed,
  },
  twitch: {
    icon: 'twitch',
    pack: 'fab',
    text: 'Vidéo Twitch',
    component: ContentBlockEmbed,
  },
  soundcloud: {
    icon: 'soundcloud',
    pack: 'fab',
    text: 'Audio Soundcloud',
    component: ContentBlockEmbed,
  },
  gdoc: {
    icon: 'file-word',
    pack: 'fas',
    text: 'Google Docs',
    component: ContentBlockEmbed,
  },
  gsheet: {
    icon: 'file-excel',
    pack: 'fas',
    text: 'Google Sheets',
    component: ContentBlockEmbed,
  },
  gform: {
    icon: 'poll',
    pack: 'fas',
    text: 'Google Forms',
    notification: `
      Pour trouver le contenu HTML :<br>
      - Rendez vous dans votre Google Forms<br>
      - Cliquez sur "Envoyer"<br>
      - Allez ensuite dans "Envoyer via <>"<br>
      - Copiez le code HTML et insérez le ci-dessous.
    `,
    notificationClass: 'is-info',
    component: ContentBlockHTML,
  },
  gcalendar: {
    icon: 'calendar-alt',
    pack: 'fas',
    text: 'Google Calendar',
    component: ContentBlockEmbed,
  },
  gslide: {
    icon: 'file-powerpoint',
    pack: 'fas',
    text: 'Google Slides',
    component: ContentBlockEmbed,
  },
  html: {
    icon: 'code',
    pack: 'fas',
    text: 'Contenu HTML',
    notification: `
      Vous pouvez insérer ici du contenu HTML provenant de
      plateformes externes telles que Padlet ou LearningApps
    `,
    notificationClass: 'is-info',
    component: ContentBlockHTML,
  },
  genially: {
    icon: 'magic',
    pack: 'fas',
    text: 'Genially',
    notification: `
      Insérez le code HTML de vos présentations
      créées sur la plateforme <b>Genially</b>
    `,
    notificationClass: 'is-info',
    component: ContentBlockHTML,
  },
  toggle: {
    icon: 'chevron-right',
    pack: 'fas',
    text: 'Cacher/Afficher',
    component: ContentBlockToggle,
  },
  slideshare: {
    icon: 'slideshare',
    pack: 'fab',
    text: 'Slideshare',
    component: ContentBlockOEmbed,
  },
  // facebook: {
  //   icon: 'facebook',
  //   pack: 'fab',
  //   text: 'Statut Facebook',
  //   component: ContentBlockEmbedo,
  // },
  instagram: {
    icon: 'instagram',
    pack: 'fab',
    text: 'Photo Instagram',
    component: () => import('@shared/components/ContentBlocks/ContentBlockEmbedo.vue'),
  },
  twitter: {
    icon: 'twitter',
    pack: 'fab',
    text: 'Tweet (X)',
    component: () => import('@shared/components/ContentBlocks/ContentBlockEmbedo.vue'),
  },
  pinterest: {
    icon: 'pinterest',
    pack: 'fab',
    text: 'Pin (Pinterest)',
    component: () => import('@shared/components/ContentBlocks/ContentBlockEmbedo.vue'),
  },
  figma: {
    icon: 'figma',
    pack: 'fab',
    text: 'Figma',
    component: ContentBlockEmbed,
  },
  github: {
    icon: 'github',
    pack: 'fab',
    text: 'Github Gist',
    component: ContentBlockOEmbed,
  },
  codeblock: {
    icon: 'file-code',
    pack: 'fas',
    text: 'Bloc de code',
    component: ContentBlockCodeBlock,
  },
  jsfiddle: {
    icon: 'jsfiddle',
    pack: 'fab',
    text: 'JSFiddle',
    component: ContentBlockEmbed,
  },
  codepen: {
    icon: 'codepen',
    pack: 'fab',
    text: 'CodePen',
    component: ContentBlockEmbed,
  },
  codesandbox: {
    icon: 'cube',
    pack: 'fas',
    text: 'CodeSandbox',
    component: ContentBlockEmbed,
  },
  chatgpt: {
    icon: 'comments',
    pack: 'fas',
    text: 'ChatGPT',
    component: ContentBlockEmbed,
  },
  tiktok: {
    icon: 'tiktok',
    pack: 'fab',
    text: 'TikTok',
    component: ContentBlockTiktokOEmbed,
  },
};
