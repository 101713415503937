<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockNotificationView from '@shared/components/ContentBlocks/Views/Notification.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockNotificationView },
  static: {
    data: { title: '', text: '', type: 'is-info' },
  },
  data: () => ({ isForm: true }),
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field label="Type d'information">
        <b-select v-model="newData.type" expanded>
          <option value="is-info">Informatif</option>
          <option value="is-success">Success</option>
          <option value="is-warning">Avertissement</option>
          <option value="is-danger">Erreur</option>
        </b-select>
      </b-field>
      <b-field label="Intitulé de l'information (facultatif)">
        <b-input v-model="newData.title" />
      </b-field>
      <b-field label="Contenu">
        <b-input v-model="newData.text" type="textarea" required />
      </b-field>
    </template>
    <template #view>
      <ContentBlockNotificationView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
