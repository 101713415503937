<script>
import { required, url } from 'vuelidate/lib/validators';
import ContentBlockWithUploadMixin from '@shared/mixins/ContentBlockWithUpload';
import ContentBlockAudioView from '@shared/components/ContentBlocks/Views/Audio.vue';

export default {
  mixins: [ContentBlockWithUploadMixin],
  components: { ContentBlockAudioView },
  static: {
    data: {
      path: '',
      srcType: 'upload',
      caption: '',
    },
  },
  data: () => ({
    isForm: true,
  }),
  validations() {
    const path = { required };

    if (this.newData.srcType === 'url') {
      path.url = url;
    }

    return {
      newData: {
        path,
        srcType: { required },
      },
    };
  },
  methods: {
    handleFile() {
      if (this.local.file.size > this.$constants.MAX_AUDIO_SIZE) {
        this.$buefy.dialog.alert(`
          Le fichier dépasse 512 Mo (trop volumineux). <br>
          Les audios trop longs sont durs à suivre et à charger dans la page,
          n'hésitez pas à découper votre fichier audio en plusieurs parties.
        `);
        return;
      }

      this.uploadFile(this.viewProps.store, 'audio');
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-field label="Source du fichier audio" v-bind="$getErrorProps($v.newData.srcType, ['required'])">
            <b-select v-model="newData.srcType" @input="newData.path = ''" expanded>
              <option value="upload">Téléversez</option>
              <option value="url">URL externe (Amazon S3, Dropbox, serveur privé...)</option>
            </b-select>
          </b-field>
        </div>
        <div
          class="column"
          :class="{
            'is-narrow': newData.srcType == 'upload',
            'is-6': newData.srcType == 'url',
          }">
          <b-field
            v-if="newData.srcType == 'url'"
            label="Fichier audio"
            v-bind="$getErrorProps($v.newData.path, ['required', 'url'])">
            <b-input v-model="newData.path" placeholder="Entrez l'URL externe du fichier audio" />
          </b-field>
          <b-field v-else label="Fichier audio" v-bind="$getErrorProps($v.newData.path, ['required'])">
            <b-upload accept="audio/mp3,audio/mpeg" v-model="local.file" expanded @input="handleFile">
              <b-button tag="a" class="button" icon-left="file-audio" outlined>
                Sélectionnez un fichier audio
              </b-button>
            </b-upload>
          </b-field>
          <p class="help is-success">
            Format .mp3
            <template v-if="newData.srcType == 'upload'">
              , max 512 Mo
            </template>
          </p>
        </div>
        <div class="column">
          <b-field label="Légende">
            <b-input v-model="newData.caption" />
          </b-field>
        </div>
        <div class="column is-12 has-text-centered">
          <template v-if="local.file">
            <b-progress
              v-if="!local.progressValue || local.progressValue < local.progressTotal"
              class="mb-2"
              type="is-success"
              :value="local.progressValue"
              :max="local.progressTotal"
              format="percent"
              show-value
            />
            <p v-else-if="saveIsDisabled">
              Traitement du média...
            </p>
            <p class="is-size-8 mb-5">
              {{ local.file.name }} - {{ localFileSize }} Mo
            </p>
          </template>
          <figure class="p-3 has-background-grey bradius-8">
            <div v-if="newData.path">
              <vue-plyr>
                <audio
                  class="is-block mx-auto bradius-8"
                  :src="localPath"
                />
              </vue-plyr>
            </div>
            <p v-else style="line-height:140px">
              Pas de audio
            </p>
          </figure>
          <p v-show="newData.path">
            <b-button type="is-text has-text-danger" @click="newData.path = ''">
              Supprimer
            </b-button>
          </p>
        </div>
      </div>
    </template>
    <template #view>
      <ContentBlockAudioView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
