<script>
import { timecode } from '@shared/config/vuelidate';
import ContentBlockEmbedView from '@shared/components/ContentBlocks/Views/Embed.vue';
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockPartialTimecodes from '@shared/components/ContentBlocks/ContentBlockPartialTimecodes.vue';

export default {
  components: {
    ContentBlockEmbedView,
    ContentBlockPartialTimecodes,
  },
  mixins: [ContentBlockMixin],
  static: {
    data: {
      url: '',
      caption: '',
      nativePlayer: false,
      hasChatEnabled: false,
      timecodes: [],
      __isWide: false,
    },
  },
  data: () => ({ isForm: true }),
  computed: {
    isYTOrVimeo() {
      return ['youtube', 'vimeo']
        .includes(this.contentBlock.type);
    },
    isYTOrYTLive() {
      return ['youtube', 'youtube_live']
        .includes(this.contentBlock.type);
    },
    isYTOrYTLiveOrVimeo() {
      return ['youtube', 'youtube_live', 'vimeo']
        .includes(this.contentBlock.type);
    },
  },
  validations: {
    newData: {
      timecodes: {
        $each: {
          time: {
            timecode,
          },
        },
      },
    },
  },
  methods: {
    handle() {
      if (this.contentBlock.type === 'youtube_live') {
        this.newData.__isWide = this.newData.hasChatEnabled;
        console.log(this.newData.__isWide);
      }

      return Promise.resolve();
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <p v-if="contentBlock.type == 'youtube_live'" class="notification">
        Pour créer votre "{{ label }}", utilisez un logiciel de streaming comme OBS
        (gratuit et accessible <a href="https://obsproject.com/fr" target="_blank">ici</a>)
      </p>
      <p v-if="contentBlock.type == 'chatgpt'" class="notification">
        Pour partager une conversation avec ChatGPT,
        cliquez sur le pictogramme de partage de la conversation et insérez le lien ci-dessous.
      </p>
      <p v-else class="notification">
        Pensez à rendre votre contenu "{{ label }}" accessible depuis l'exterieur (masqué, non répertorié, public...)
      </p>
      <b-field :label="`Lien ${label} *`">
        <b-input v-model="newData.url" required />
      </b-field>
      <b-field label="Légende">
        <b-input v-model="newData.caption" />
      </b-field>
      <div v-if="contentBlock.type == 'youtube_live'" class="field">
        <b-switch v-model="newData.hasChatEnabled">
          Activer le tchat YouTube
        </b-switch>
      </div>
      <div v-if="isYTOrYTLiveOrVimeo" class="field">
        <b-switch v-model="newData.nativePlayer">
          Apparence par défaut du lecteur {{ isYTOrYTLive ? 'YouTube' : 'Vimeo' }}
        </b-switch>
      </div>
      <ContentBlockPartialTimecodes
        v-if="isYTOrVimeo && !newData.nativePlayer"
        v-model="newData.timecodes"
        :v="$v.newData.timecodes"
      />
    </template>
    <template #view>
      <ContentBlockEmbedView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
